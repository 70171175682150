
import { defineComponent, PropType } from 'vue';
import UserEntity from '@/class/UserEntity';
import UserAbsence from '@/class/UserAbsence';
import Multiselect from '@vueform/multiselect';
import lodash from 'lodash';

export default defineComponent({
  name: 'AbsenceWindowDetail',
  emits: ['addAbsence'],
  components: {
    Multiselect,
  },
  data() {
    return {
      inputs: {
        jour: [],
        centreDeFrais: this.costCenterGroup !== undefined ? this.costCenterGroup[0] : undefined,
        heuresAbsence: 8,
        notes: '',
      },
      days: [] as Array<number>,
      options: [
        { value: '1', label: 'Lundi' },
        { value: '2', label: 'Mardi' },
        { value: '3', label: 'Mercredi' },
        { value: '4', label: 'Jeudi' },
        { value: '5', label: 'Vendredi' },
      ],
      newOptions: [] as Array<{ value: string, label: string }>,
      isTotalWeek: false,
    };
  },
  props: {
    costCenterGroup: {
      type: Object as PropType<Array<string>> | undefined,
      required: false,
    },
    weekNumber: {
      type: Number,
      required: true,
    },
    yearNumber: {
      type: Number,
      required: true,
    },
    operator: {
      type: UserEntity as PropType<UserEntity>,
      required: true,
    },
    holidays: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    absencePlanned: {
      type: Array as PropType<Array<UserAbsence>>,
      required: true,
    },
  },
  methods: {
    addAbsence() {
      const days = this.inputs.jour;
      if (days.length === 0 && !this.isTotalWeek) {
        return;
      }

      const absence = new UserAbsence(this.operator);
      if (this.inputs.centreDeFrais !== undefined) {
        absence.setCostCenterGroup(this.inputs.centreDeFrais);
      } else {
        absence.setCostCenterGroup('any');
      }

      if (this.isTotalWeek) {
        const daysWeek = this.newOptions.map((option) => parseInt(option.value, 10));
        absence.setDay(daysWeek);
        absence.setHours(8);
      } else {
        absence.setDay(days.map(Number));
        absence.setHours(parseFloat(String(this.inputs.heuresAbsence)));
      }

      absence.setComment(this.inputs.notes);
      absence.setYearNumber(this.yearNumber);
      absence.setWeekNumber(this.weekNumber);
      this.$emit('addAbsence', absence);
    },
    defaultHours() {
      if (this.inputs.jour.length === 1 && this.inputs.jour[0] === '5') {
        this.inputs.heuresAbsence = 5;
      } else {
        this.inputs.heuresAbsence = 8;
      }
    },
  },
  computed: {
    getOption() {
      return this.holidays.length === 0 ? this.newOptions : this.newOptions.filter((option) => !this.holidays.includes(parseInt(option.value, 10)));
    },
  },
  mounted() {
    lodash.forEach(this.absencePlanned, (absence) => {
      absence.getDay().forEach((day) => {
        this.days.push(day);
      });
    });

    this.newOptions = this.options.filter((option) => !this.days.includes(parseInt(option.value, 10)));
  },
  unmounted() {
    this.days = [];
  },
});
