
import { defineComponent, PropType } from 'vue';
import UserEntity from '@/class/UserEntity';
import UserPlanning from '@/class/UserPlanning';
import UserAbsence from '@/class/UserAbsence';
import operatorPlanningTaskBus from '@/bus/operatorPlanningTaskBus';
import Notiflix from 'notiflix';
import { CostCenterWeek, CostCenterWeekHours } from '@/bus/costCenterWeekHours';
import CostCenterWeekHoursType from '@/type/costCenterWeekHoursType';
import draggableWindowsShowBus from '@/bus/draggableWindowsShowBus';

export default defineComponent({
  name: 'WeekDetail',
  props: {
    weekNumber: {
      type: Number,
      required: true,
    },
    yearNumber: {
      type: Number,
      required: true,
    },
    operator: {
      type: UserEntity,
      required: true,
    },
    userPlannings: {
      type: Object as PropType<Array<UserPlanning>>,
      required: true,
    },
    userAbsences: {
      type: Object as PropType<Array<UserAbsence>>,
      required: true,
    },
  },
  methods: {
    isDefaultsTasks(userPlannings: Array<UserPlanning>) {
      return userPlannings.filter((up) => up.getIsDefault()).length > 0;
    },
    initCostCenterHours() {
      this.costCenterHours = new Array<{userPlanning: UserPlanning, newHours: number}>();
      this.userPlannings.forEach((up) => {
        if (this.costCenterHours.filter((upHours) => upHours.userPlanning.internalId === up.internalId).length > 0) return;
        this.costCenterHours.push({
          userPlanning: up,
          newHours: up.getHours(),
        });
      });
    },
    prevSave() {
      const id = `${this.yearNumber}/${this.weekNumber}`;
      const groupName = this.userPlannings.map((up) => up.getCostCenterGroup());
      const costCenterGroup: CostCenterWeekHoursType = { id, groupName };

      CostCenterWeekHours.on('TasksTime', this.saveNewHours);
      CostCenterWeek.trigger('getWeekTaskTime', costCenterGroup);
    },
    saveNewHours() {
      const totalNewHours = this.costCenterHours.reduce((hours, upHours) => upHours.newHours + hours, 0);

      if (totalNewHours > parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10)) {
        this.confirmExceededHoursOperator();
      } else {
        this.saveChange();
        this.saveComplete = true;
        setTimeout(() => {
          this.saveComplete = false;
        }, 2000);
      }
      CostCenterWeekHours.off('TasksTime', this.saveNewHours);
    },
    confirmExceededHoursOperator() {
      draggableWindowsShowBus.trigger('close', false);
      Notiflix.Confirm.show(
        'Confirmation',
        `Le temps alloue a l'operator dépasse ${process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME}h, voulez-vous continuer ?`,
        'Oui',
        'Non',
        () => {
          draggableWindowsShowBus.trigger('open', true);
          this.saveChange();
          this.saveComplete = true;
          setTimeout(() => {
            this.saveComplete = false;
          }, 2000);
        },
        () => {
          draggableWindowsShowBus.trigger('open', true);
        },
      );
    },
    saveChange() {
      if (this.isDefaultsTasks(this.userPlannings)) {
        if (this.costCenterHours.map((upHours) => upHours.userPlanning.getHours()).join(',') !== this.costCenterHours.map((upHours) => upHours.newHours).join(',')) {
          this.costCenterHours.forEach((upHours) => {
            const userPlanning = new UserPlanning(this.operator as UserEntity);
            userPlanning.setCostCenterGroup(upHours.userPlanning.getCostCenterGroup());
            userPlanning.setYearNumber(this.yearNumber);
            userPlanning.setWeekNumber(this.weekNumber);
            userPlanning.setIsDefault(false);
            userPlanning.setHours(parseFloat(String(upHours.newHours)));
            operatorPlanningTaskBus.trigger('addPlanningTask', userPlanning);
          });
        }
      } else {
        this.costCenterHours.forEach((upHours) => {
          if (upHours.newHours !== upHours.userPlanning.getHours()) {
            upHours.userPlanning.setHours(parseFloat(String(upHours.newHours)));
            upHours.userPlanning.save();
          }
        });
      }
    },
  },
  watch: {
    // Je ne comprend pas pourquoi ce watcher est trigger a chaque clic gauche sur la fenêtre
    // Je suis donc obligé de check si les deux tableaux sont differents
    // ¯\_(ツ)_/¯
    userPlannings(b: Array<UserPlanning>, a: Array<UserPlanning>) {
      let same = true;

      if (b.length !== a.length) {
        same = false;
      }
      if (same) {
        b.forEach((upb, index) => {
          if (!same) return;
          if (upb.internalId !== a[index].internalId) {
            same = false;
          }
        });
      }

      if (!same) {
        this.initCostCenterHours();
      }
    },
    costCenterHours: {
      deep: true,
      handler() {
        this.costCenterHours.forEach((upHours, index) => {
          if (typeof upHours.newHours === 'string') {
            this.costCenterHours[index].newHours = 0;
          }
        });
      },
    },
  },
  data() {
    return {
      saveComplete: false,
      costCenterHours: new Array<{userPlanning: UserPlanning, newHours: number}>(),
    };
  },
  computed: {
    totalHours() {
      return this.userPlannings.reduce((previousValue, currentValue) => previousValue + currentValue.getHours(), 0);
    },
  },
  mounted() {
    this.initCostCenterHours();
  },
});
