
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import UserStore from '@/store/UserStore';
import UserEntity from '@/class/UserEntity';
import CostCenter from '@/class/CostCenter';
import DatePicker from 'vue-datepicker-next';
import DateHelper from '@/helper/DateHelper';
import UserPlanning from '@/class/UserPlanning';
import Notiflix from 'notiflix';
import CostCenterHelper from '@/helper/CostCenterHelper';
import DraggableWindow from '@/components/draggable-window/DraggableWindow.vue';
import WeekDetail from '@/components/absence-management/WeekDetail.vue';
import MainEndpoint from '@/endpoints/main';
import UserHelper from '@/helper/UserHelper';
import { AxiosError } from 'axios';
import AbsenceWindowDetail from '@/components/absence-management/AbsenceWindowDetail.vue';
import UserAbsence from '@/class/UserAbsence';
import OperatorPlanningDetail from '@/components/absence-management/OperatorPlanningDetail.vue';
import operatorPlanningTaskBus from '@/bus/operatorPlanningTaskBus';
import { CostCenterWeek, CostCenterWeekHours } from '@/bus/costCenterWeekHours';
import CostCenterWeekHoursType from '@/type/costCenterWeekHoursType';
import TimeHelper from '@/helper/TimeHelper';
import moment from 'moment';

export default defineComponent({
  name: 'OperatorsList',
  components: {
    AbsenceWindowDetail,
    WeekDetail,
    DatePicker,
    DraggableWindow,
    OperatorPlanningDetail,
  },
  computed: {
    UserEntity() {
      return UserEntity;
    },
    TimeHelper() {
      return TimeHelper;
    },
    ...mapState(UserStore, ['operators']),
    filteredOperators(): Array<UserEntity> {
      if (this.filterText.length === 0) {
        return this.operators as Array<UserEntity>;
      }

      return (this.operators as Array<UserEntity>).filter((operator) => {
        const textOperator = `${operator.FirstName} ${operator.Name}`;
        return textOperator.toLowerCase()
          .includes(this.filterText.toLowerCase());
      });
    },
    costCenterGroup() {
      return Object.keys(CostCenter.groups);
    },
    weekNumberFilter(): number {
      return DateHelper.getWeek(this.filter.week);
    },
  },
  methods: {
    sortOperatorByDefaultCostCenter(operators: Array<UserEntity>): Array<UserEntity> {
      operators.sort((a, b) => {
        const aDefaultCostCenter = this.getUserPlanningFromOperatorAndDefault(a);
        const bDefaultCostCenter = this.getUserPlanningFromOperatorAndDefault(b);

        if (aDefaultCostCenter.length === 0 && bDefaultCostCenter.length === 0) {
          return 0;
        }

        if (aDefaultCostCenter.length === 0) {
          return 1;
        }

        if (bDefaultCostCenter.length === 0) {
          return -1;
        }

        return CostCenterHelper.sortCostCenter(this.getLongestDefaultCostCenter(aDefaultCostCenter), this.getLongestDefaultCostCenter(bDefaultCostCenter));
      });

      return operators;
    },
    getLongestDefaultCostCenter(defaultCostCenter: Array<UserPlanning>): string {
      let longest = defaultCostCenter[0];
      let name = defaultCostCenter[0].getCostCenterGroup();
      defaultCostCenter.forEach((userPlanning) => {
        if (userPlanning.getHours() > longest.getHours()) {
          longest = userPlanning;
          name = userPlanning.getCostCenterGroup();
        }
      });

      return name;
    },
    startListenerMultiplication(operator: UserEntity, index: number, indexUP: number): void {
      this.multiplyUserPlanning.copy.isOver = true;
      this.multiplyUserPlanning.copy.operator = operator;
      this.multiplyUserPlanning.copy.indexWeek = index;
      this.multiplyUserPlanning.copy.index = indexUP;
    },
    stopListenerMultiplication(): void {
      this.multiplyUserPlanning.copy.isOver = false;
      this.multiplyUserPlanning.copy.operator = undefined;
      this.multiplyUserPlanning.copy.indexWeek = -1;
      this.multiplyUserPlanning.copy.index = -1;
    },
    copyMultiplyCostCenter(): void {
      if (!this.multiplyUserPlanning.copy.isOver || this.multiplyUserPlanning.copy.operator === undefined || this.multiplyUserPlanning.copy.index === -1) {
        return;
      }

      const indexWeek = this.multiplyUserPlanning.copy.indexWeek;
      const operator = this.multiplyUserPlanning.copy.operator;
      const index = this.multiplyUserPlanning.copy.index;
      if (typeof operator === 'undefined') {
        return;
      }

      const userPLanning = this.getUserPlanningFromIndexAndOperator(indexWeek, operator);
      this.multiplyUserPlanning.copy.costCenterGroup = userPLanning[index].getCostCenterGroup();
      this.multiplyUserPlanning.copy.hours = userPLanning[index].getHours();
      this.multiplyUserPlanning.copy.isDefault = userPLanning[index].getIsDefault();

      if (this.multiplyUserPlanning.copy.isDefault) {
        Notiflix.Notify.success(`Copie de la tache par defaut ${this.multiplyUserPlanning.copy.costCenterGroup} ${this.multiplyUserPlanning.copy.hours}h effectuée`);
      } else {
        Notiflix.Notify.success(`Copie de ${this.multiplyUserPlanning.copy.costCenterGroup} ${this.multiplyUserPlanning.copy.hours}h effectuée`);
      }
    },
    startListenerPaste(operator: UserEntity, index: number): void {
      this.multiplyUserPlanning.paste.isOver = true;
      this.multiplyUserPlanning.paste.operator = operator;
      this.multiplyUserPlanning.paste.indexWeek = index;
    },
    stopListenerPaste(): void {
      this.multiplyUserPlanning.paste.isOver = false;
      this.multiplyUserPlanning.paste.operator = undefined;
      this.multiplyUserPlanning.paste.indexWeek = -1;
    },
    pasteMultiplyCostCenter(): void {
      if (!this.multiplyUserPlanning.paste.isOver || this.multiplyUserPlanning.copy.costCenterGroup === '' || this.multiplyUserPlanning.copy.hours === -1) {
        return;
      }

      const operator = this.multiplyUserPlanning.paste.operator;
      const index = this.multiplyUserPlanning.paste.indexWeek;
      const group = this.multiplyUserPlanning.copy.costCenterGroup;
      const hours = this.multiplyUserPlanning.copy.hours;
      const isDefault = this.multiplyUserPlanning.copy.isDefault;
      let weekNumber = this.getWeekNumberFromIndex(index);
      let yearNumber = this.getYearNumberFromIndex(index);

      const userPlanning = new UserPlanning(operator as UserEntity);

      if (isDefault) {
        weekNumber = 0;
        yearNumber = 0;
        userPlanning.setIsDefault(isDefault);
      }

      userPlanning.setCostCenterGroup(group);
      userPlanning.setYearNumber(yearNumber);
      userPlanning.setWeekNumber(weekNumber);
      userPlanning.setHours(hours);

      // eslint-disable-next-line max-len
      if (this.weeksUserPlanning[`${yearNumber}/${weekNumber}`].find((up) => (up.getOperator() === userPlanning.getOperator()) && (up.getCostCenterGroup() === userPlanning.getCostCenterGroup())) !== undefined) {
        Notiflix.Notify.failure('Le centre de frais existe déjà !');
      } else {
        this.saveCostCenterGroup(userPlanning);
      }
    },
    getWeekTaskstime(costWeekTask: CostCenterWeekHoursType): void {
      const weekTaskTime = new Array<number>();
      costWeekTask.groupName.forEach((group) => {
        weekTaskTime.push(this.getWeekTaskTime(costWeekTask.id, group));
      });

      CostCenterWeekHours.trigger('TasksTime', weekTaskTime);
    },
    getWeekTaskTime(week: string, costCenterGroup: string): number {
      const weekPlanning = this.weeksUserPlanning[week];
      const defaultWeekPlanning = this.weeksUserPlanning['0/0'];
      // const maxTimeTask = CostCenterHelper.getTotalHoursByGroup(costCenterGroup) * 5;
      const maxTimeTask = 1;

      if (weekPlanning === undefined || defaultWeekPlanning === undefined) {
        return 0;
      }

      defaultWeekPlanning.map((dp) => {
        if (dp.getCostCenterGroup() === costCenterGroup) {
          if (weekPlanning.filter((wp) => dp.getOperator() === wp.getOperator()).length === 0) {
            weekPlanning.push(dp);
          }
        }
        return 0;
      });

      const totalTimeTask = weekPlanning.reduce((acc, up) => {
        if (up.getCostCenterGroup() === costCenterGroup) {
          return acc + up.getHours();
        }
        return acc;
      }, 0);

      return maxTimeTask - totalTimeTask;
    },
    getOperatorHoursWeek(operator: UserEntity, id: string): number {
      const operatorTaskHours = this.weeksUserPlanning[id].filter((up) => up.getOperator().User === operator.User).reduce((acc, up) => acc + up.getHours(), 0);
      const remainingTime = parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10) - operatorTaskHours;
      if (remainingTime > 0) {
        return remainingTime;
      }
      return 0;
    },
    firstNameLowerCase(firstName: string): string {
      let newName = firstName.toLowerCase();
      newName = firstName.charAt(0).toUpperCase() + newName.slice(1);
      return newName;
    },
    removeDeletedUserPlannings() {
      Object.keys(this.weeksUserPlanning).forEach((upKey) => {
        this.weeksUserPlanning[upKey].forEach((up, index) => {
          if (up.internalId === undefined) {
            this.weeksUserPlanning[upKey].splice(index, 1);
          }
        });
      });
    },
    removeDeletedUserAbsences() {
      Object.keys(this.weeksUserAbsences).forEach((uaKey) => {
        this.weeksUserAbsences[uaKey].forEach((ua, index) => {
          if (ua.internalId === undefined) {
            this.weeksUserAbsences[uaKey].splice(index, 1);
          }
        });
      });
    },
    getUserPlanningFromOperatorAndDefault(operator: UserEntity) {
      const userPlanning = this.weeksUserPlanning['0/0'] ?? [];
      return userPlanning.filter((up) => up.getOperator().User === operator.User && up.getIsDefault());
    },
    getUserPlanningFromIndexAndOperator(index: number, operator: UserEntity) {
      const weekNumber = this.getWeekNumberFromIndex(index);
      const yearNumber = this.getYearNumberFromIndex(index);

      return this.getUserPlanningFromYearWeekAndOperator(yearNumber, weekNumber, operator);
    },
    getUserPlanningFromYearWeekAndOperator(yearNumber: number, weekNumber: number, operator: UserEntity) {
      let userPlanning = this.weeksUserPlanning[`${yearNumber}/${weekNumber}`] ?? [];
      userPlanning = userPlanning.filter((up) => up.getOperator().User === operator.User);
      userPlanning = userPlanning.filter((up) => !up.getIsDefault());

      if (userPlanning.length === 0) {
        return this.getUserPlanningFromOperatorAndDefault(operator);
      }

      return userPlanning;
    },
    getHolidayFromIndex(index: number): Array<number> {
      const weekNumber = this.getWeekNumberFromIndex(index);
      const yearNumber = this.getYearNumberFromIndex(index);
      const days: Array<number> = [];

      if (this.holidays[yearNumber][weekNumber] !== undefined) {
        this.holidays[yearNumber][weekNumber].forEach((date: any) => {
          let day = new Date(date[1]).getDay();
          if (day === 0) {
            day = 7;
          }
          days.push(day);
        });
      }
      return days;
    },
    getUserAbsenceFromIndexAndOperator(index: number, operator: UserEntity) {
      const weekNumber = this.getWeekNumberFromIndex(index);
      const yearNumber = this.getYearNumberFromIndex(index);

      const absenceDayPlanned = this.getUserAbsenceFromYearWeekAndOperator(yearNumber, weekNumber, operator).sort((a, b) => this.sortDayAbesence(a, b));
      if (absenceDayPlanned === undefined || absenceDayPlanned.length === 0) {
        const defaultOperator = new UserEntity();
        defaultOperator.User = 'ALL';

        return this.getUserAbsenceFromYearWeekAndOperator(yearNumber, weekNumber, defaultOperator).sort((a, b) => this.sortDayAbesence(a, b));
      }
      return absenceDayPlanned;
    },
    sortDayAbesence(a: any, b: any) {
      const diff = a.getDay()[0] - b.getDay()[0];
      if (diff === 0) {
        return b.getDay().length - a.getDay().length;
      }
      return diff;
    },
    getUserAbsenceFromYearWeekAndOperator(yearNumber: number, weekNumber: number, operator: UserEntity) {
      const userAbsences = this.weeksUserAbsences[`${yearNumber}/${weekNumber}`] ?? [];
      const absences = userAbsences.filter((ua) => ua.getOperator().User === operator.User);
      return absences.concat(userAbsences.filter((ua) => ua.getOperator().Name === 'holiday'));
    },
    retrieveAllIndex() {
      const promises = new Array<Promise<void>>();
      Notiflix.Loading.circle({
        backgroundColor: 'rgba(0,0,0,0)',
        svgColor: 'rgb(0,187,255)',
        svgSize: '150px',
      });
      for (let i = 1; i <= 8; i += 1) {
        promises.push(new Promise<void>((resolve, reject) => {
          const weekNumber = this.getWeekNumberFromIndex(i);
          const yearNumber = this.getYearNumberFromIndex(i);
          this.retrieveWeek(yearNumber, weekNumber, (err) => {
            if (err === undefined) {
              resolve();
            } else {
              reject(err);
            }
          });
        }));
      }

      this.weeksUserPlanning['0/0'] = new Array<UserPlanning>();
      this.weeksUserAbsences['0/0'] = new Array<UserAbsence>();
      this.retrieveWeek(0, 0, undefined);

      Promise.all(promises).catch((err: AxiosError) => {
        console.log(err);
        Notiflix.Notify.failure('Une erreur est apparu lors de chargement des absences.');
      }).finally(() => {
        Notiflix.Loading.remove();
      });
    },
    retrieveWeek(year: number, week: number, clb: ((error: undefined | AxiosError) => void) | undefined = undefined) {
      const id = `${year}/${week}`;

      if (this.weeksUserPlanning[id] === undefined) {
        this.weeksUserPlanning[id] = new Array<UserPlanning>();
      }
      if (this.weeksUserAbsences[id] === undefined) {
        this.weeksUserAbsences[id] = new Array<UserAbsence>();
      }
      if (this.weeksRetrieved.includes(id)) {
        if (clb !== undefined) {
          clb(undefined);
          return;
        }
      }

      if (year !== 0) {
        if (!(year in this.holidays)) {
          MainEndpoint.getAxiosInstance().get(`holiday/${year}`).then((response) => {
            this.holidays[year] = response.data;
            this.addHoliday(year, week, id);
          }).catch((err: AxiosError) => {
            console.log(err);
          });
        } else {
          this.addHoliday(year, week, id);
        }
      }

      MainEndpoint.getAxiosInstance().get(`userplanning/get-userplanning/${id}`).then((response) => {
        this.weeksRetrieved.push(id);
        response.data.plannings.forEach((responseData: any) => {
          const user = UserHelper.getUserById(responseData.operatorString);
          if (user === undefined) return;
          const userPlanning = new UserPlanning(user);
          userPlanning.fillFromJSON(responseData);
          this.weeksUserPlanning[id].push(userPlanning);
        });
        response.data.absences.forEach((responseData: any) => {
          if (responseData.operatorString === 'ALL') {
            const user = new UserEntity();
            user.User = 'ALL';
            const userAbsence = new UserAbsence(user);
            userAbsence.fillFromJSON(responseData);
            this.weeksUserAbsences[id].push(userAbsence);
          } else {
            const user = UserHelper.getUserById(responseData.operatorString);
            if (user === undefined) return;
            const userAbsence = new UserAbsence(user);
            userAbsence.fillFromJSON(responseData);
            this.weeksUserAbsences[id].push(userAbsence);
          }
        });
        if (clb !== undefined) {
          clb(undefined);
        }
      }).catch((err: AxiosError) => {
        if (clb !== undefined) {
          clb(err);
        }
      });
    },
    addHoliday(year: number, week: number, id: string) {
      if (year !== 0 && this.holidays[year][week] !== undefined) {
        const userHoliday = new UserEntity();
        userHoliday.Name = 'holiday';

        this.holidays[year][week].forEach((date: any) => {
          const day = new Date(date[1]).getDay();
          if (day === 0 || day === 6) {
            return;
          }

          const holiday = new UserAbsence(userHoliday);
          holiday.setDay([day]);
          holiday.setYearNumber(year);
          holiday.setWeekNumber(week);
          holiday.setComment(date[0]);
          holiday.setCostCenterGroup('Férié');

          if (day === 5) {
            holiday.setHours(5);
          } else {
            holiday.setHours(8);
          }
          this.weeksUserAbsences[id].push(holiday);
        });
      }
    },
    removeCostCenter(userPlanning: UserPlanning) {
      let isWindowsOpen = false;
      if (this.operatorWindow.open) {
        this.operatorWindow.open = false;
        isWindowsOpen = true;
      }

      Notiflix.Confirm.show(
        'Supression',
        `Voulez-vous vraiment supprimer ${userPlanning.getCostCenterGroup()
        } (${userPlanning.getHours()}h) de l'opérateur ${userPlanning.getOperator().FirstName} ${userPlanning.getOperator().Name} ?`,
        'Oui',
        'Annuler',
        () => {
          userPlanning.delete(() => {
            this.removeDeletedUserPlannings();
            if (isWindowsOpen) {
              this.operatorWindow.open = true;
            }
          });
        },
        () => {
          if (isWindowsOpen) {
            this.operatorWindow.open = true;
          }
        },
      );
    },
    removeAbsence(userAbsence: UserAbsence) {
      Notiflix.Confirm.show(
        'Supression',
        `Voulez-vous vraiment supprimer l'absence ${userAbsence.getCostCenterGroup()
        } (${userAbsence.getHours()}h) de l'opérateur ${userAbsence.getOperator().FirstName} ${userAbsence.getOperator().Name} ?`,
        'Oui',
        'Annuler',
        () => {
          userAbsence.delete(() => {
            this.removeDeletedUserAbsences();
          });
        },
        undefined,
      );
    },
    openDetail(e: MouseEvent, operator: UserEntity, index: number) {
      e.preventDefault();
      this.detailWeek = {
        operator,
        weekNumber: this.getWeekNumberFromIndex(index),
        yearNumber: this.getYearNumberFromIndex(index),
      };
    },
    getYearNumberFromIndex(index: number) {
      const weekNumberFirst = this.getWeekNumberFromIndex(1);
      const weekNumber = this.getWeekNumberFromIndex(index);
      if (weekNumberFirst > weekNumber) {
        return this.filter.week.getFullYear() + 1;
      }
      return this.filter.week.getFullYear();
    },
    getWeekNumberFromIndex(index: number) {
      return ((this.weekNumberFilter - 2 + index) % 52) + 1;
    },
    getBackgroundColorFromGroup(group: string) {
      if (group === 'Férié') {
        return 'bg-blue-400';
      }
      return CostCenterHelper.getBackgroundColorFromGroup(group);
    },
    getTypeAbsence(group: string) {
      if (group === 'Férié') {
        return 'Férié';
      }

      return 'Abs';
    },
    addCostCenterGroup(group: string) {
      const operator = this.mousePositionGroupSelect.operator;
      if (operator === undefined) {
        return;
      }
      const index = parseInt(this.mousePositionGroupSelect.openId, 10);
      const weekNumber = this.getWeekNumberFromIndex(index);
      const yearNumber = this.getYearNumberFromIndex(index);
      const userPlanning = new UserPlanning(operator as UserEntity);
      const id = `${yearNumber}/${weekNumber}`;

      userPlanning.setCostCenterGroup(group);
      userPlanning.setYearNumber(yearNumber);
      userPlanning.setWeekNumber(weekNumber);

      // if (this.getUserPlanningFromIndexAndOperator(index, operator).length === 0) {
      //   parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10));
      // }

      userPlanning.setHours(this.getOperatorHoursWeek(operator, id));
      this.saveCostCenterGroup(userPlanning);
      this.closeGroupSelector();
    },
    saveCostCenterGroup(userPlanning: UserPlanning) {
      const weekNumber = userPlanning.getWeekNumber();
      const yearNumber = userPlanning.getYearNumber();

      userPlanning.save(() => {
        Notiflix.Notify.success('Le centre de frais a été ajouté !');
        if (this.weeksUserPlanning[`${yearNumber}/${weekNumber}`] === undefined) {
          this.weeksUserPlanning[`${yearNumber}/${weekNumber}`] = new Array<UserPlanning>();
        }
        this.weeksUserPlanning[`${yearNumber}/${weekNumber}`].push(userPlanning);
      });
    },
    filterDateBackward() {
      const newDate = new Date(this.filter.week);
      newDate.setHours(-7 * 24);
      this.filter.week = newDate;
    },
    filterDateForward() {
      const newDate = new Date(this.filter.week);
      newDate.setHours(7 * 24);
      this.filter.week = newDate;
    },
    addAbsence(absence: UserAbsence) {
      const id = `${absence.getYearNumber()}/${absence.getWeekNumber()}`;
      if (this.weeksUserAbsences[id] === undefined) {
        this.weeksUserAbsences[id] = new Array<UserAbsence>();
      }
      absence.save(() => {
        this.weeksUserAbsences[id].push(absence);
      });

      this.closeAbsenceCreator();
    },
    closeAbsenceCreator() {
      this.absenceCreator.open = false;
      this.absenceCreator.operator = undefined;
      this.absenceCreator.index = -1;
    },
    OpenGlobalAbsenceCreator(id: number) {
      this.absenceCreator.index = id;
      this.absenceCreator.open = true;
      const user = new UserEntity();
      user.User = 'ALL';
      this.absenceCreator.operator = user; // all operator
    },
    openAbsenceCreator(e: MouseEvent, id: number, operator: UserEntity) {
      if (this.getUserPlanningFromIndexAndOperator(id, operator).length === 0) {
        Notiflix.Notify.failure('Aucun planning défini pour cet opérateur !');
        return;
      }
      this.absenceCreator.operator = operator;
      this.absenceCreator.index = id;
      this.absenceCreator.open = true;
    },
    openGroupSelector(e: MouseEvent, id: string, operator: UserEntity) {
      this.mousePositionGroupSelect.x = e.clientX;
      this.mousePositionGroupSelect.y = e.clientY;
      this.mousePositionGroupSelect.openId = id;
      this.mousePositionGroupSelect.open = true;
      this.mousePositionGroupSelect.operator = operator;
    },
    closeGroupSelector() {
      this.mousePositionGroupSelect.openId = '';
      this.mousePositionGroupSelect.open = false;
      this.mousePositionGroupSelect.operator = undefined;
    },
    operatorDetail(operator: UserEntity) {
      this.operatorWindow.open = !this.operatorWindow.open;
      this.operatorWindow.operator = operator;
    },
  },
  data() {
    return {
      weeksUserPlanning: {} as { [key: string]: Array<UserPlanning> },
      weeksUserAbsences: {} as { [key: string]: Array<UserAbsence> },
      weeksRetrieved: new Array<string>(),
      filter: {
        week: DateHelper.setToMonday(new Date()),
        formatter: {
          stringify: (date: Date) => {
            const weekNumberStart = moment(date).week();
            const endWeek = moment(date).add(7, 'week');
            const weekNumberEnd = endWeek.week();
            return ` ${weekNumberStart} ~ ${weekNumberEnd}`;
          },
        },
      },
      detailWeek: undefined as undefined | { weekNumber: number, yearNumber: number, operator: UserEntity },
      langObject: {
        formatLocale: {
          firstDayOfWeek: 1,
          firstWeekContainsDate: 3,
        },
        monthBeforeYear: true,
      },
      filterText: '',
      absenceCreator: {
        open: false,
        index: -1,
        operator: undefined as undefined | UserEntity,
      },
      mousePositionGroupSelect: {
        open: false,
        x: 0,
        y: 0,
        openId: '',
        operator: undefined as undefined | UserEntity,
      },
      operatorWindow: {
        open: false,
        operator: undefined as undefined | UserEntity,
      },
      holidays: {} as { [key: number]: Array<Array<object>>},
      multiplyUserPlanning: {
        copy: {
          isOver: false,
          index: -1,
          indexWeek: -1,
          operator: undefined as undefined | UserEntity,
          costCenterGroup: '',
          hours: -1,
          isDefault: false,
        },
        paste: {
          isOver: false,
          indexWeek: -1,
          operator: undefined as undefined | UserEntity,
        },
      },
    };
  },
  watch: {
    'filter.week': function filterWeek() {
      this.retrieveAllIndex();
    },
  },
  mounted() {
    this.retrieveAllIndex();
    operatorPlanningTaskBus.on('addPlanningTask', this.saveCostCenterGroup);
    CostCenterWeek.on('getWeekTaskTime', this.getWeekTaskstime);
    operatorPlanningTaskBus.on('removePlanningTask', this.removeCostCenter);

    document.onkeyup = (event: any) => {
      if (event.key === 'c' && event.ctrlKey === true) {
        this.copyMultiplyCostCenter();
      } else if (event.key === 'v' && event.ctrlKey === true) {
        this.pasteMultiplyCostCenter();
      }
    };
  },
  unmounted() {
    operatorPlanningTaskBus.off('addPlanningTask', this.saveCostCenterGroup);
    CostCenterWeek.off('getWeekTaskTime', this.getWeekTaskstime);
    operatorPlanningTaskBus.off('removePlanningTask', this.removeCostCenter);
    document.onkeyup = null;
  },
});
