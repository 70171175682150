import Serializable from '@/helper/SerializationHelper';
import UserEntity from '@/class/UserEntity';
import MainEndpoint from '@/endpoints/main';
import Notiflix from 'notiflix';

class UserAbsence extends Serializable {
  private readonly operator: UserEntity;

  private weekNumber = 0;

  private yearNumber = 0;

  private hours = 0;

  private costCenterGroup = '';

  // Lundi = 1, Mardi = 2, etc...
  private days: number[] = [];

  private comment = '';

  public internalId = undefined as undefined | number;

  fillFromJSON(data: any): void {
    this.days = JSON.parse(data.days).map(Number);
    this.weekNumber = data.weekNumber;
    this.yearNumber = data.yearNumber;
    this.hours = data.hours;
    this.costCenterGroup = data.costCenterGroup;
    this.comment = data.comment;
    this.internalId = data.internalId;
  }

  constructor(operator: UserEntity) {
    super();
    this.operator = operator;
  }

  public delete(clb: (() => void) | undefined = undefined): void {
    if (this.internalId === undefined) {
      if (clb !== undefined) {
        clb();
      }
      return;
    }
    MainEndpoint.getAxiosInstance().post('user-absence/delete', { id: this.internalId }).then(() => {
      this.internalId = undefined;

      if (clb !== undefined) {
        clb();
      }
    }).catch(() => {
      Notiflix.Notify.failure('Une erreur est apparu lors de la suppression de l\'absence.');
    });
  }

  public save(clb: (() => void) | undefined = undefined): void {
    const daysString = JSON.stringify(this.days);
    const data = {
      weekNumber: this.weekNumber,
      yearNumber: this.yearNumber,
      hours: this.hours,
      costCenterGroup: this.costCenterGroup,
      operator: this.operator.User,
      comment: this.comment,
      days: daysString,
      internalId: null as null | number,
    };

    if (this.internalId !== undefined) {
      data.internalId = this.internalId;
    }
    MainEndpoint.getAxiosInstance().post('user-absence/save', data).then((response) => {
      this.internalId = response.data.internalId;

      if (clb !== undefined) {
        clb();
      }
    });
  }

  public setWeekNumber(weekNumber: number) {
    this.weekNumber = weekNumber;
  }

  public getWeekNumber() {
    return this.weekNumber;
  }

  public setYearNumber(yearNumber: number) {
    this.yearNumber = yearNumber;
  }

  public getYearNumber() {
    return this.yearNumber;
  }

  public getOperator() {
    return this.operator;
  }

  public getHours() {
    return this.hours;
  }

  public getTotalHours() {
    const friday = this.days.find((day) => day === 5);
    if (friday !== undefined && this.hours === 8) {
      return (this.hours * this.days.length) - 3;
    }

    return this.hours * (this.days.length);
  }

  public setHours(hours: number) {
    this.hours = hours;
  }

  public getCostCenterGroup() {
    return this.costCenterGroup;
  }

  public setCostCenterGroup(costCenterGroup: string) {
    this.costCenterGroup = costCenterGroup;
  }

  public getDay() {
    return this.days;
  }

  public setDay(days: Array<number>) {
    this.days = days;
  }

  public setComment(comment: string) {
    this.comment = comment;
  }

  public getComment() {
    return this.comment;
  }

  public getDayString(short: boolean) {
    let dayString = '';

    if (this.days.length === 5) {
      return 'Semaine';
    }

    this.days.sort((a, b) => a - b);

    this.days.forEach((day) => {
      let dayName = '';
      switch (day) {
        case 1:
          dayName = 'Lundi';
          break;
        case 2:
          dayName = 'Mardi';
          break;
        case 3:
          dayName = 'Mercredi';
          break;
        case 4:
          dayName = 'Jeudi';
          break;
        case 5:
          dayName = 'Vendredi';
          break;
        case 6:
          dayName = 'Samedi';
          break;
        case 7:
        case 0:
          dayName = 'Dimanche';
          break;
        default:
          dayName = 'Erreur';
      }

      if (short || this.days.length > 2) {
        dayString += dayName.substring(0, 3);
      } else {
        dayString += dayName;
      }

      dayString += ', ';
    });

    if (short && this.days.length === 4) {
      dayString = dayString.substring(0, dayString.length - 2);
    }

    return dayString.substring(0, dayString.length - 2);
  }
}

export default UserAbsence;
