import UserEntity from '@/class/UserEntity';
import Serializable from '@/helper/SerializationHelper';
import MainEndpoint from '@/endpoints/main';
import Notiflix from 'notiflix';

class UserPlanning extends Serializable {
  private readonly operator: UserEntity;

  private isDefault = false;

  private weekNumber = 0;

  private yearNumber = 0;

  private hours = 0;

  private costCenterGroup = '';

  public internalId = undefined as undefined | number;

  constructor(operator: UserEntity) {
    super();
    this.operator = operator;
  }

  public delete(clb: (() => void) | undefined = undefined): void {
    if (this.internalId === undefined) {
      if (clb !== undefined) {
        clb();
      }
      return;
    }
    MainEndpoint.getAxiosInstance().post('userplanning/delete', { id: this.internalId }).then(() => {
      this.internalId = undefined;

      if (clb !== undefined) {
        clb();
      }
    }).catch(() => {
      Notiflix.Notify.failure('Une erreur est apparu lors de la suppression du planning.');
    });
  }

  public static show() {
    MainEndpoint.getAxiosInstance().get('userplanning/get-userplanning/').then((response) => {
      const data = response.data;
      return data.planning;
    }).catch(() => {
      Notiflix.Notify.failure('Une erreur est apparu lors de la suppression du planning.');
    });
  }

  public save(clb: (() => void) | undefined = undefined): void {
    const data = {
      isDefault: this.isDefault,
      weekNumber: this.weekNumber,
      yearNumber: this.yearNumber,
      hours: this.hours,
      costCenterGroup: this.costCenterGroup,
      operator: this.operator.User,
      internalId: null as null | number,
    };
    if (this.internalId !== undefined) {
      data.internalId = this.internalId;
    }
    MainEndpoint.getAxiosInstance().post('userplanning/save', data).then((response) => {
      this.internalId = response.data.internalId;
      if (clb !== undefined) {
        clb();
      }
    });
  }

  public getIsDefault() {
    return this.isDefault;
  }

  public setIsDefault(isDefault: boolean) {
    this.isDefault = isDefault;
  }

  public getWeekNumber() {
    return this.weekNumber;
  }

  public setWeekNumber(weekNumber: number) {
    this.weekNumber = weekNumber;
  }

  public getYearNumber() {
    return this.yearNumber;
  }

  public setYearNumber(yearNumber: number) {
    this.yearNumber = yearNumber;
  }

  public getCostCenterGroup() {
    return this.costCenterGroup;
  }

  public setCostCenterGroup(costCenterGroup: string) {
    this.costCenterGroup = costCenterGroup;
  }

  public getHours() {
    return this.hours;
  }

  public setHours(hours: number) {
    this.hours = hours;
  }

  public getOperator() {
    return this.operator;
  }
}

export default UserPlanning;
