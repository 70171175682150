
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import AffaireStore from '@/store/AffaireStore';
import Affaire from '@/class/Affaire';
import MainEndpoint from '@/endpoints/main';
import Notify from '@/helper/Notification';
import moment from 'moment';
import BomHelper from '@/helper/BomHelper';
import BomGroupStatus from '@/enum/BomGroupStatus';

export default defineComponent({
  name: 'ListStatusAffaires',
  data() {
    return {
      validateHistory: [] as any[],
    };
  },
  props: {
    typeStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(AffaireStore, {
      newAffaires: 'newAffaires',
      affairesDelays: 'affairesDelays',
      sousTraitanceDelays: 'sousTraitanceDelays',
    }),
    getAffaire() {
      switch (this.typeStatus) {
        case 'newAffaires':
          return this.newAffaires;
        case 'affairesDelays':
          return this.affairesDelays;
        case 'sousTraitanceDelays':
          console.log(this.sousTraitanceDelays[0].delaySousTraitanceTaskList[0][0][1]);
          return this.sousTraitanceDelays;
        default:
          return [];
      }
    },
  },
  methods: {
    getListAffairesTableHeader() {
      switch (this.typeStatus) {
        case 'newAffaires':
          return [
          ];
        case 'affairesDelays':
          return [
            'Raison du problème',
            'Rang et poste',
          ];
        case 'sousTraitanceDelays':
          return [
            'N° bon de commande fournisseur',
            'Fournisseur',
            'Probleme',
            'Décalage',
          ];
        default:
          return [];
      }
    },
    getHistoryListTableHeader() {
      switch (this.typeStatus) {
        case 'newAffaires':
          return [
            'Numéro d’affaire',
            'Code pièce + désignation',
            'Code client',
          ];
        case 'affairesDelays':
          return [
            'Numéro d’affaire',
            'Code pièce + désignation',
            'Code client',
            // 'Raison du problème',
            // 'Rang et poste',
          ];
        case 'sousTraitanceDelays':
          return [
            'Numéro d’affaire',
            'Code pièce + désignation',
            'Code client',
            // 'Numéro du bon de commande fournisseur',
            // 'Numéro fournisseur',
          ];
        default:
          return [];
      }
    },
    getTitleType() {
      switch (this.typeStatus) {
        case 'newAffaires':
          return 'Liste des nouvelles Affaires';
        case 'affairesDelays':
          return 'Liste des affaires en retard';
        case 'sousTraitanceDelays':
          return 'List des sous traitance en retard';
        default:
          return '';
      }
    },
    getReplanningTitleType() {
      switch (this.typeStatus) {
        case 'newAffaires':
          return 'Historique de planification des nouvelles affaires ';
        case 'affairesDelays':
        case 'sousTraitanceDelays':
          return 'Historique des affaires re-planifier';
        default:
          return '';
      }
    },
    async filterAffaire(affaire: Affaire) {
      const associateList = await affaire.getAssociateAffaires();
      const productsReservations = affaire.Ranks[0].Postes[0].Boms[0].ProductionReservations[0].Job;
      associateList.push(productsReservations);

      associateList.forEach((affaireLinkId: number) => {
        if (!AffaireStore().getIsLoad(affaireLinkId)) {
          AffaireStore().loadJob(affaireLinkId.toString(), () => {
            // console.log();
          });
        }
      });

      associateList.unshift(affaire.Job);
      AffaireStore().multiFilter(associateList);
    },
    validAffaire(affaire: Affaire) {
      switch (this.typeStatus) {
        case 'newAffaires':
          AffaireStore().removeNewAffaire(affaire);
          break;
        case 'affairesDelays':
          affaire.setNomenclatureStatus();
          if (affaire.IsOverDelays()) {
            Notify.notifyFailure('L\'Affaire N\'est pas valide');
            return 1;
          }
          AffaireStore().removeAffaireDelays(affaire);
          break;
        case 'sousTraitanceDelays':
          affaire.setNomenclatureStatus();
          // if (affaire.IsSousTraitanceOverDelays()) {
          //  Notify.notifyFailure('L\'Affaire N\'est pas valide');
          //  return 1;
          // }
          AffaireStore().removeSousTraitanceDelays(affaire);
          break;
        default:
          break;
      }

      // sa a edit
      const valideAffaire = {
        numberAffaire: affaire.Job,
        codePiece: affaire.Product,
        designation: affaire.Designation1,
        codeClient: affaire.CustomerCode,
        // numberOrderSupplier: affaire.SupplierOrderNumber,
        // codeSupplier: affaire.SupplierCode,
        validateDate: moment().format('l'),
      };

      this.validateHistory.push(valideAffaire);
      return 0;
    },
    getDetailFromStatusNom(statusArray: [[number, BomGroupStatus]]) {
      if (statusArray === undefined) {
        return 'Aucun problème';
      }

      let mainStatus = statusArray[0][1];
      statusArray.forEach((status: [number, BomGroupStatus]) => {
        mainStatus = BomHelper.getStatusBom(status[1], mainStatus);
      });

      return BomHelper.getInfosNomenclature(mainStatus);
    },
    displayDelay(delay: number) {
      if (delay > 0) {
        return `+${delay} semaines`;
      }

      if (delay < 0) {
        return `${delay} semaines`;
      }

      return 'Aucun décalage';
    },
  },
  mounted() {
    MainEndpoint.getAxiosInstance()
      .get(`affaire/get-history/${this.typeStatus}`)
      .then((response) => {
        this.validateHistory = response.data;
      })
      .catch(() => {
        Notify.notifyFailure("Impossible de recuperais l'historique des affaires valides");
      });
  },
});
