
import { defineComponent, PropType } from 'vue';
import UserEntity from '@/class/UserEntity';
import UserPlanning from '@/class/UserPlanning';
import CostCenter from '@/class/CostCenter';
import operatorPlanningTaskBus from '@/bus/operatorPlanningTaskBus';
import CostCenterHelper from '@/helper/CostCenterHelper';
import Notiflix from 'notiflix';
import draggableWindowsShowBus from '@/bus/draggableWindowsShowBus';
import TimeHelper from '@/helper/TimeHelper';

export default defineComponent({
  name: 'OperatorPlanningDetail',
  data() {
    return {
      saveComplete: false,
      userPlanningArray: {} as { [key: string]: number},
      mousePositionGroupSelect: {
        open: false,
        x: 0,
        y: 0,
        openId: '',
        operator: undefined as undefined | UserEntity,
      },
    };
  },
  computed: {
    TimeHelper() {
      return TimeHelper;
    },
    costCenterGroup() {
      return Object.keys(CostCenter.groups);
    },
  },
  props: {
    operator: {
      type: UserEntity,
      required: true,
    },
    userCenterDefault: {
      type: Object as PropType<Array<UserPlanning>>,
      required: true,
    },
  },
  methods: {
    getDefaultRemaningHours() {
      const operatorTaskHours = this.userCenterDefault.filter((up) => up.getOperator().User === this.operator.User).reduce((acc, up) => acc + up.getHours(), 0);
      const remainingTime = parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10) - operatorTaskHours;
      if (remainingTime > 0) {
        return remainingTime;
      }
      return 0;
    },
    getDefaultPlanningFromOperator() {
      return this.userCenterDefault;
    },
    openGroupSelector(e: MouseEvent, id: string) {
      this.mousePositionGroupSelect.x = e.offsetX;
      this.mousePositionGroupSelect.y = e.offsetY;
      this.mousePositionGroupSelect.openId = id;
      this.mousePositionGroupSelect.open = true;
      this.mousePositionGroupSelect.operator = this.operator;
    },
    closeGroupSelector() {
      this.mousePositionGroupSelect.openId = '';
      this.mousePositionGroupSelect.open = false;
      this.mousePositionGroupSelect.operator = undefined;
    },
    addCostCenter(group: string) {
      const userPlanning = new UserPlanning(this.operator);
      userPlanning.setIsDefault(true);
      userPlanning.setCostCenterGroup(group);
      userPlanning.setHours(this.getDefaultRemaningHours());
      operatorPlanningTaskBus.trigger('addPlanningTask', userPlanning);
      this.userPlanningArray[group] = TimeHelper.fixFormatHoursMinuteNumber(this.getDefaultRemaningHours());
      this.closeGroupSelector();
    },
    getBackgroundColorFromGroup(group: string) {
      return CostCenterHelper.getBackgroundColorFromGroup(group);
    },
    initUserCenterDefault() {
      this.userCenterDefault.forEach((userPlanning) => {
        const groupName = userPlanning.getCostCenterGroup();
        if (groupName !== undefined && groupName !== null) {
          this.userPlanningArray[groupName] = TimeHelper.fixFormatHoursMinuteNumber(userPlanning.getHours());
        }
      });
    },
    removeCostCenter(userPlanning: UserPlanning) {
      operatorPlanningTaskBus.trigger('removePlanningTask', userPlanning);
    },
    saveNewHours() {
      const remainingTime = this.userCenterDefault.reduce((acc, up) => acc + up.getHours(), 0);
      if (remainingTime > parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10)) {
        this.confirmSave();
      } else {
        this.userCenterDefault.forEach((userPlanning) => {
          const groupName = userPlanning.getCostCenterGroup();
          if (groupName !== undefined && groupName !== null) {
            this.userPlanningArray[groupName] = TimeHelper.fixFormatHoursMinuteNumber(this.userPlanningArray[groupName]);
            userPlanning.setHours(this.userPlanningArray[groupName]);
            userPlanning.save();
          }
        });
      }
      this.saveComplete = true;
      setTimeout(() => {
        this.saveComplete = false;
      }, 1500);
    },
    confirmSave() {
      draggableWindowsShowBus.trigger('close', false);
      Notiflix.Confirm.show(
        'Sauvegarder les heures',
        'Êtes-vous sûr de vouloir sauvegarder les heures ?',
        'Oui',
        'Non',
        () => {
          this.userCenterDefault.forEach((userPlanning) => {
            const groupName = userPlanning.getCostCenterGroup();
            if (groupName !== undefined && groupName !== null) {
              userPlanning.setHours(this.userPlanningArray[groupName]);
              userPlanning.save();
            }
          });
          draggableWindowsShowBus.trigger('open', true);
        },
        () => {
          draggableWindowsShowBus.trigger('open', true);
        },
      );
    },
  },
  mounted() {
    this.initUserCenterDefault();
  },
});

