
import { defineComponent } from 'vue';
import OperatorsList from '@/components/absence-management/OperatorsList.vue';

export default defineComponent({
  name: 'AbsenceManagement',
  components: {
    OperatorsList,
  },

});
